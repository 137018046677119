import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
import { colors } from '@uchiru/primitives';
import { Box, Flex, Heading, Link, StyledOcticon } from '@primer/components';
import { Link as LinkIcon } from '@primer/octicons-react';
import { palettes, variables } from '../../src/color-variables';
import { PaletteTable, PaletteCell, overlayColor } from '../../src/color-system';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Color palette`}</h2>
    <Flex flexWrap="wrap" mr={-2} mdxType="Flex">
  {palettes.concat({
        title: 'Black',
        name: 'black',
        value: colors.black
      }, {
        title: 'White',
        name: 'white',
        value: colors.white,
        props: {
          className: 'border'
        }
      }, {
        title: 'Blanket',
        name: 'blanket',
        value: colors.blanket
      }).map(({
        name,
        title,
        value,
        props = {}
      }) => <Flex.Item as={Link} href={`#${name}`} color={overlayColor(value)} flex="1 1 auto" bg={value} p={3} mr={2} mb={2} fontWeight="bold" key={name} {...props}>
        {title}
      </Flex.Item>)}
    </Flex>
    <h2>{`Color variables`}</h2>
    <Flex flexWrap="wrap" mr={[0, 0, -4]} mdxType="Flex">
  {palettes.map(({
        name,
        title,
        value,
        values
      }) => <Flex id={name} flexDirection="column" width={[1, 1, 1 / 2]} pr={[0, 0, 4]} mb={4} key={name} mdxType="Flex">
      <Flex as={Link} href={`#${name}`} bg={value} color={overlayColor(value)} px={3} pt={4} style={{
          borderBottom: `1px solid ${overlayColor(value)}`
        }} alignItems="center" mdxType="Flex">
        <Flex.Item color="inherit !important" flex="1 1 auto">
          <Heading as="div" fontSize={4} pb={1} mdxType="Heading">
            {title}
          </Heading>
        </Flex.Item>
        <StyledOcticon icon={LinkIcon} color="inherit !important" height={20} mdxType="StyledOcticon" />
      </Flex>
      <PaletteTable columns={['variable', 'value']} values={[{
          variable: name,
          value,
          slug: name
        }].concat(values)} hasHeader={false} cellPadding="8px 16px" mdxType="PaletteTable" />
    </Flex>)}
    </Flex>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      